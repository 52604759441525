import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import AboutComponent from "./about/about.component";
import AwardsComponent from "./awards/awards.component";
import GalleryComponent from "./gallery/gallery.component";
import HomeComponent from "./home/home.component";
import InstituteComponent from "./institute/institute.component";
import MediaComponent from "./media/media.component";

const routes: Routes = [
    { path:'',  pathMatch:'full', component: HomeComponent},
    { path: 'about', component: AboutComponent },
    { path: 'awards', component: AwardsComponent },
    { path: 'media', component: MediaComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'institute', component: InstituteComponent }
  ];
  

  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }