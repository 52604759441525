<div class="main-content-wrapper">
  <div class="content-wrapper d-flex align-items-stretch">
    <div class="left-image-container">
      <div class="image-container"></div>
    </div>
    <div class="main-information-wrapper flex-grow-1 add-scroll">
      <div class="px-5 d-flex flex-column">
     
        <div class="py-2 pl-3 position-relative">
          <h2 class="content-heading">Institute</h2>
        </div>

        <p>
          Tejaswini School of Performing Arts (TSPA) is a registered Trust.
          Established in 2013 having senior artists in the advisory board aims
          to enrich the cultural exposure of art lovers in the cultural capital.
          TSPA is specialised in teaching the Tanjore Bani of Bharatanatyam.
        </p>

        <img
          src="../../assets/images/tspa_logo.jpg"
          class="img-fluid"
          alt="..."
        />

        <div>
          <p>
            The Institution believes in taking forward the Indian art form to
            younger learners, thus dedicated to the propagation and protection
            of Indian classical arts with original essence from the Natya
            Sastra. The Institution is affiliated to Prayag Sangeet
            Samiti-Prayagraj, thus encouraging students to take up Bharatanatyam
            exam.
          </p>
        </div>

        <div class="d-flex">
          <img
            src="../../assets/images/ap_tspa_students1.JPG"
            class="rounded col-6 m-1"
            alt="..."
          />
          <img
            src="../../assets/images/ap_tspa_students2.JPG"
            class="rounded col-6 m-1"
            alt="..."
          />
        </div>

        <div class="py-2">
          <p>
            We at TSPA use art as a medium for promoting social rights and
            equipping girls with skills to be independent. We are training a few
            girl students with weak financial backgrounds. We believe the
            smallest donation will make a world of difference for an aspiring
            student to pursue dance as a profession, carving a new generation of
            cultural torch bearers. TSPA feels the hopes and dreams to excel in
            the field of performing arts should not be limited to the privileged
            class only.
          </p>
        </div>

        <div class="d-flex">
          <img
            src="../../assets/images/ap_tspa_students3.JPG"
            class="rounded col-6 m-1"
            alt="..."
          />
          <img
            src="../../assets/images/ap_tspa_students4.JPG"
            class="rounded col-6 m-1"
            alt="..."
          />
        </div>

        <div class="d-flex">
          <img
            src="../../assets/images/ap_tspa_students5.JPG"
            class="rounded col-6 m-1"
            alt="..."
          />
          <img
            src="../../assets/images/ap_tspa_students6.JPG"
            class="rounded col-6 m-1"
            alt="..."
          />
        </div>

       <div class="py-2"></div>
      </div>
    </div>
  </div>
</div>
