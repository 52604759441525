import { Component } from "@angular/core";


@Component({
    selector:'portfolio-awards',
    templateUrl: 'awards.component.html',
    styleUrls:['awards.component.scss']
})
 export default class AwardsComponent {
    constructor(){

    }
}