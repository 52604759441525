<div class="main-wrapper">
  <div class="options-wrapper d-flex align-items-stretch justify-content-center">
    <div class="option" routerLink="/about">
      <div class="image-container" id="about-image"></div>
      <div class="bottom-text"><span>About</span></div>
    </div>
    <div class="option" routerLink="/media">
      <div class="image-container" id="media-image"></div>
      <div class="bottom-text"><span>Media</span></div>
    </div>
    <div class="option" routerLink="/gallery">
      <div class="image-container" id="gallery-image"></div>
      <div class="bottom-text"><span>Gallery</span></div>
    </div>
    <div class="option" routerLink="/institute">
      <div class="image-container" id="institute-image"></div>
      <div class="bottom-text">
        <span>Institute</span>
      </div>
    </div>
  </div>
</div>