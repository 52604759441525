import { Component } from "@angular/core";


@Component({
    selector:'portfolio-media',
    templateUrl: 'media.component.html',
    styleUrls:['media.component.scss']
})
 export default class MediaComponent {
    constructor(){

    }
}