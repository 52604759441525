import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import HomeComponent from './home/home.component';
import { AppRoutingModule } from './app.routes';
import AboutComponent from './about/about.component';
import AwardsComponent from './awards/awards.component';
import MediaComponent from './media/media.component';
import GalleryComponent from './gallery/gallery.component';
import InstituteComponent from './institute/institute.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    AwardsComponent,
    MediaComponent,
    GalleryComponent,
    InstituteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
