<div class="main-content-wrapper">
  <div class="content-wrapper d-flex align-items-stretch">
    <div class="left-image-container ">
      <div class="image-container">

      </div>
    </div>
    <div class="main-information-wrapper flex-grow-1">
      <div class="px-5 d-flex flex-column">

        <div class="col-12 pt-2 pb-3 back-navigation">
          <div class="btn btn-outline-light">
            <i class="fas fa-chevron-left"></i>
            <span class="px-2 d-inline-block">Back</span>
          </div>
        </div>
        <div class="py-2 pl-3 position-relative">
          <h2 class="content-heading">Awards</h2>
        </div>
        <div class="d-flex justify-content-around">


          <div class="card mb-3" style="max-width: 540px;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="https://picsum.photos/seed/picsum/200/150" class="img-fluid rounded-start" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Light card title</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
              </div>
            </div>
          </div>


         

          <div class="card border-light mb-3" style="max-width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Media</h5>
              <p class="card-text">Some quick example text to build on the card. <i class="fas fa-arrow-right"></i></p>  
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>