<div class="d-flex flex-column portfolio-wrapper">
  <header>
    <div class="container-fluid">
      <div class="row text-center">
        <div
          class="col-12 py-3"
          [ngClass]="{
            'd-flex justify-content-between align-items-center':
              currentRoute != '/'
          }"
        >
          <h1 [routerLink]="['/']">
            Aashna <span class="text-capitalize">Priyamvada</span>
          </h1>
          <ng-template [ngIf]="currentRoute != '/'">
            <ul class="nav justify-content-end">
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="ap_acstive_link"
                  routerLink="/about"
                  >About</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="ap_active_link"
                  routerLink="/media"
                  >Media</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="ap_active_link"
                  routerLink="/gallery"
                  >Gallery</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="ap_active_link"
                  routerLink="/institute"
                  >Institute</a
                >
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
  </header>

  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <div class="container-fluid">
      <div class="row text-center">
        <div class="col-6 py-3">
          <div id="copy-text" class="text-start">
            &copy; Copyright 2022. All Rights Reserved.
          </div>
        </div>
        <div class="col-6 py-3">
          <ul class="nav justify-content-end">
            <li class="nav-item">
              <a
                href="https://www.facebook.com/AashnaPriyamvada/
              "
                class="px-2"
                ><i class="fab fa-facebook"></i> Facebook</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://instagram.com/tejaswini_school?igshid=YmMyMTA2M2Y="
                class="px-2"
              >
                <i class="fab fa-instagram"></i> Instagram</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</div>
