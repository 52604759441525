<div class="main-content-wrapper">
  <div class="content-wrapper d-flex align-items-stretch">
    <div class="left-image-container ">
      <div class="image-container">
    
      </div>
    </div>
    <div class="main-information-wrapper flex-grow-1 add-scroll">
      <div class="px-5 d-flex flex-column">


        <div class="py-2 pl-3 position-relative">
          <h2 class="content-heading">Media</h2>
        </div>
        
    
          <div class="media-timeline">
            <div class="timeline">
                <div class="timeline-row">
                  <div class="timeline-time">
                    Ahmedabad Mirror<small>November 2021</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_1.jpeg" class="rounded d-block" alt="..." >
                    
                  </div>
                </div>
            
                <div class="timeline-row">
                  <div class="timeline-time">
                    Dainik Bhaskar<small>November 2020</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_7.jpeg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
                <div class="timeline-row">
                  <div class="timeline-time">
                    Badhata Rajasathan<small>October 2020</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_3.jpeg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
                <div class="timeline-row">
                  <div class="timeline-time">
                    Rajasthan Patrka<small>August 2020</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_14.jpeg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
                <div class="timeline-row">
                  <div class="timeline-time">
                    Dainik Jagran<small>March 2020</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_10.jpg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
                <div class="timeline-row">
                  <div class="timeline-time">
                    Metro Mix<small>December 2019</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_12.jpg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
                <div class="timeline-row">
                  <div class="timeline-time">
                    Dina Mani<small>June 18</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_11.jpg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
             
                <div class="timeline-row">
                  <div class="timeline-time">
                    Dainik Bhasak <small>December 2017</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_2.jpg" class="rounded d-block" alt="..." >
                  </div>
                </div>

                <div class="timeline-row">
                  <div class="timeline-time">
                    Dainik Jagran <small>March 2016</small>
                  </div>
                  <div class="timeline-content">
                    <img src="../../assets/images/ap_media_9.jpeg" class="rounded d-block" alt="..." >
                  </div>
                </div>
            
              </div>
            </div>
   





        

      </div>
    </div>
  </div>