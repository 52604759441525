import { Component } from "@angular/core";


@Component({
    selector:'portfolio-institute',
    templateUrl: 'institute.component.html',
    styleUrls:['institute.component.scss']
})
 export default class InstituteComponent {
    constructor(){

    }
}