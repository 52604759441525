import { Component } from "@angular/core";


@Component({
    selector:'portfolio-gallery',
    templateUrl: 'gallery.component.html',
    styleUrls:['gallery.component.scss']
})
 export default class GalleryComponent {
    constructor(){

    }
}