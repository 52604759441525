<div class="main-content-wrapper">
  <div class="content-wrapper d-flex align-items-stretch">
    <div class="left-image-container">
      <div class="image-container"></div>
    </div>
    <div class="main-information-wrapper flex-grow-1 add-scroll">
      <div class="px-5 d-flex flex-column">
      
        <div class="py-2 pl-3 position-relative">
          <h2 class="content-heading">About</h2>
        </div>

        <div>
          <p>
            Ms. Aashna started learning Bharatanatyam at a very tender age of 4
            years. She has 19+ years of training and continues to learn the
            artform of Bharatanatyam under the tutelage of the legendary Padma
            Vibhushan Dr. Yamini Krishnamurthi. Aashna has Visharad from Prayag
            Sangeet Samiti-Prayagraj. She aspires to imbibe and internalize the
            intricacies and essence of this art form to the best of her ability.
            She also has an excellent academic record.
          </p>
          <p>
            Aashna has been performing extensively after her Arangetram. she has
            had the opportunity to perform in various events organized at
            various venues. Aashna uses her stage name as Aashna Priyamvada, the
            name fondly given by Yamini Amma. As mentioned by her guru, “Aashna
            is one of the most promising disciples” of Nritya kaustubha Cultural
            Society- Yamini School of Dance. She attempts to achieve seeking
            inspiration from her Guru and other doyens of the art form. She
            intends to take forward the rich cultural that she imbibes from her
            guru.
          </p>
          <p class="fs-5">
            An ardent art lover since childhood and a devoted learner of art,
            Aashna is one of the rare artists who is also trained in Odissi
            under Sudarshan Sahoo and Kathak from Mrs Shruti Sinha that widened
            her Art knowledge in various Classical Dance form receiving
            appreciation from art lovers for her immaculate technique and
            evocative sahityam.
          </p>

          <p>
            Aashna has performed extensively in India and abroad. She has been
            bestowed with many accolades & titles, notably among them the Kala
            Pratibha award from Parmath Foundation Delhi, Nritya Vaidurya award
            Devdasi Nruty Mandir,Govt. of Odisha, International Exchange of art
            and culture award, Performed at Khajuraho(Madhya Pradesh), National
            classical dance & music Samaraho organized by Sangeet Natak Akademi
            in Odissa to name a few.
          </p>
          <p>
            She is associated with number of Organizations, Reputed schools &
            NGOs for training and conducting workshop for aspirants in
            Bharatanatyam. She had choreographed Folk Dances of South India for
            DTEA School with 150 students for 72nd Republic Day Parade held at
            Rajpath, New Delhi in 2021 for which the school got the recognition
            and award.
          </p>
          <p>
            Aashna being an Art Practitioner and a mentor in her own Dance
            School-Tejaswini School of Performing Arts believes, “Art is the
            true essence of life that needs to be explored by younger
            generations while upholding the cultural and spiritual values of
            great Indian traditions”.
          </p>
        </div>

        <div class="py-2">
          <hr />
          <span class="fs-3">Guru DR Yamini Amma’s words to Aashna, </span>
          <p class="fw-light fs-4">
            <i class="fa-solid fa-quote-left"></i>
            My Golden Moments are when I have total rapport with my disciple like you. There is no medium of expression that is more integrative in human spirit and also universal in its appeal other than our classical dance Bharatanatyam. I wish you lot of inspiration to express our art and culture.
            <i class="fa-solid fa-quote-right"></i> 
          </p>
          <hr />
        </div>
        
      </div>
    </div>
  </div>
</div>
