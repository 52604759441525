import { Component } from "@angular/core";

@Component({
    selector:'portfolio-about',
    templateUrl: 'about.component.html',
    styleUrls:['about.component.scss']
})
 export default class AboutComponent {
    constructor(){

    }
}