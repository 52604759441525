<div class="main-content-wrapper">
  <div class="content-wrapper d-flex align-items-stretch">

    <div class="main-information-wrapper flex-grow-1 add-scroll">
      <div class="px-5 d-flex flex-column">
 
        <div class="py-2 pl-3 position-relative">
          <h2 class="content-heading">Gallery</h2>
        </div>

        <div class="gallery">
          <div class="gallery__column">
            <a
              href="javascript:void();"
              
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                  src="../../assets/gallery/ap_website_gallery_1.jpg"
                  alt="Portrait by Jessica Felicio"
                  class="gallery__image"
                />
           
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_2.jpg"
                  alt="Portrait by Oladimeji Odunsi"
                  class="gallery__image"
                />
               
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_3.jpg"
                  alt="Portrait by Alex Perez"
                  class="gallery__image"
                />
              
              </figure>
            </a>
          </div>

          <div class="gallery__column">
            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_4.jpg"
                  alt="Portrait by Noah Buscher"
                  class="gallery__image"
                />
                
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_5.jpg"
                  alt="Portrait by Ivana Cajina"
                  class="gallery__image"
                />
             
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_6.jpg"
                  alt="Portrait by Sam Burriss"
                  class="gallery__image"
                />
               
              </figure>
            </a>
          </div>

          <div class="gallery__column">
            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_7.jpg"
                  alt="Portrait by Mari Lezhava"
                  class="gallery__image"
                />
            
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_8.jpg"
                  alt="Portrait by Ethan Haddox"
                  class="gallery__image"
                />
          
              </figure>
            </a>

            <a
              href="https://unsplash.com/@mr_geshani"
              target="_blank"
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_9.jpg"
                  alt="Portrait by Amir Geshani"
                  class="gallery__image"
                />
             
              </figure>
            </a>
          </div>

          <div class="gallery__column">
            <a
              href="javascript:void();"
              
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_10.jpg"
                  alt="Portrait by Guilian Fremaux"
                  class="gallery__image"
                />
             
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_11.jpg"
                  alt="Portrait by Jasmin Chew"
                  class="gallery__image"
                />
              
              </figure>
            </a>

            <a
            href="javascript:void();"
            
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                src="../../assets/gallery/ap_website_gallery_12.jpg"
                  alt="Portrait by Dima DallAcqua"
                  class="gallery__image"
                />
               
              </figure>
            </a>
          </div>
        </div>

     
      </div>
    </div>
  </div>
</div>
