import { Component } from "@angular/core";

@Component({
    selector:'portfolio-home',
    templateUrl: './home.component.html',
    styleUrls:['./home.component.scss'],
})
 export default class HomeComponent {
    constructor(){

    }
}