import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnDestroy  {
  title = 'portfolio';
  event$ 
  currentRoute = "/"
 
  constructor(private router: Router) {
    this.event$
      =this.router.events
          .subscribe(
            (event: NavigationEnd) => {
              if(event instanceof NavigationStart) {
                this.currentRoute = event.url;
              }
            });
  }
 
  ngOnDestroy() {
    this.event$.unsubscribe();
  }
}
